import { format } from 'date-fns';
import { Link, useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import divider from '../images/divider-colored.svg';

const RelatedArticles = ({ choice }) => {
  return (
    <section className='section'>
      <div className='subscribe-blog-heading'>
        <div className='columns'>
          <div className='column'>
            <img src={divider} alt='divider' />
            <p style={{ color: '#333' }}>Related Articles</p>
            <hr style={{ border: 'solid 1px #c1c1c1', marginTop: '20px' }} />
            <BlogGrid typeToShow={choice} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default RelatedArticles;

const BlogCard = props => (
  <Link to={`/resources/${props.node.slug}/`}>
    <div
      className='card card-media-match-ra'
      style={{
        maxHeight: '100%',
        borderRadius: '10px',
      }}
    >
      {' '}
      <div className='card-image'>
        {props.node.featuredImage && (
          <div className='card-image-container'>
            <center>
              <img
                className='featured'
                style={{
                  backgroundColor: props.node.color,
                  height: '40vh',
                  width: '100%',
                }}
                src={props.node.featuredImage.file.url}
                alt={props.node.title}
              />
            </center>
          </div>
        )}
        <div className='card-content'>
          <div className='media-content'>
            {' '}
            <p className='card-type is-centered' style={{ width: '100%' }}>
              {props.node.type}
            </p>
            <p className='card-title is-4'> {props.node.title}</p>
            <p className='card-author'>
              {props.node.author} |{' '}
              {format(new Date(props.node.publishedDate), 'iii do MMM, yyyy')} |{' '}
              {props.node.body?.childMarkdownRemark?.timeToRead} min read
            </p>
          </div>
        </div>
      </div>
    </div>
  </Link>
);

const BlogGrid = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulBlogPosting(limit: 2, sort: { fields: publishedDate }) {
          edges {
            node {
              type
              title
              id
              slug
              color
              publishedDate
              author
              featuredImage {
                # fixed(quality: 100, width: 200) {
                #   ...GatsbyContentfulFixed_tracedSVG
                # }
                file {
                  url
                }
              }
              #   excerpt {
              #     childMarkdownRemark {
              #       excerpt(pruneLength: 150)
              #     }
              #   }
              body {
                childMarkdownRemark {
                  timeToRead
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <>
      <div
        className='columns is-multiline related-articles-matches'
        style={{ maxWidth: '100%' }}
      >
        {data.allContentfulBlogPosting.edges &&
          data.allContentfulBlogPosting.edges.map(edge => (
            <div className='column column-is-12-mobile is-6-fullhd  is-6-desktop is-6-tablet'>
              <BlogCard key={edge.node.id} node={edge.node} />
            </div>
          ))}
      </div>
    </>
  );
};
