/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { graphql, Link } from 'gatsby';
import { format } from 'date-fns';
import ShareSheet from '../components/ShareSheet';
import SubscribeBlog from '../components/SubscribeBlog';
import SEO from '../components/seo';
import Modal from 'react-modal';
import Snakke from 'react-snakke';
import RelatedArticles from '../components/RelatedArticles';
import Layout from '../components/Layout';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import image from '../images/case-study.svg';
import logo from '../images/Blue.svg';
import blogBanner from '../images/blogBanner.png';

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        twitterUsername
      }
    }
    contentfulBlogPosting(slug: { eq: $slug }) {
      caseStudyFile {
        file {
          url
        }
      }
      title
      author
      slug
      tags
      color
      # logo {
      #   file {
      #     url
      #   }
      # }
      type
      blogHero {
        file {
          url
        }
      }
      publishedDate
      featuredImage {
        # fluid(maxWidth: 1050, quality: 100) {
        #   ...GatsbyContentfulFluid
        # }
        file {
          url
        }
      }
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
    }
  }
`;

const BlogPost = props => {
  const onFireAlert = e => {
    window.open(props.data.contentfulBlogPosting.caseStudyFile.file.url);
  };
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.8)',
      display: 'flex',
      'align-items': 'center',
    },
    ul: {
      listStyle: 'disc',
    },
    content: {
      top: '56%',
      left: '50%',
      right: 'auto',
      zIndex: '1000',
      bottom: 'auto',
      maxHeight: '95%',
      maxWidth: '75%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#f9f9f9',
    },
  };
  const data = props.data.contentfulBlogPosting;

  const [openModal, setOpenModal] = React.useState(false);
  return (
    <React.Fragment style={{ backgroundColor: '#f9fcff' }}>
      <Layout>
        <SEO
          title={props.data.contentfulBlogPosting.title}
          description={`By ${props.data.contentfulBlogPosting.author}`}
          article={true}
          image={blogBanner}
          author={props.data.contentfulBlogPosting.author}
          datePublished={props.data.contentfulBlogPosting.publishedDate}
          bannerImage={blogBanner}
        />

        <div className='container-blog-hero'>
          <div
            className='container-her-matches'
            style={{
              backgroundColor: data.color,
              maxWidth: '100vw',
            }}
          >
            {data.type !== 'Case Studies' && (
              <img
                alt=''
                src={data?.featuredImage?.file?.url}
                className='image-per-blog'
              />
            )}
            {data.type === 'Case Studies' && data.blogHero && (
              <img
                src={data.blogHero.file.url}
                alt='blog hero'
                className='image-per-blog'
              />
            )}
          </div>
          <div className='blog-hero-title'>
            {/* <div className='blog-building-title'>
              <Link to='/resources'> BUILDING DIGITAL BANKING</Link> {'>'}{' '}
              {data.type}
            </div> */}
            <div className='blog-title-hero'>{data.title}</div>
            <br />
            {data.type !== 'Case Studies' && `${data.author} | `}
            {format(new Date(data.publishedDate), 'iii do MMM, yyyy')}
          </div>
        </div>
        <div className='blogpost-layout'>
          <ShareSheet
            className='is-hidden-mobile'
            style={{ position: '-webkit-sticky', position: 'sticky', top: 70 }}
            isDesktop={true}
            title={data.title}
            url={`${props.data.site.siteMetadata.siteUrl}/resources/${data.slug}`}
            userName={props.data.site.siteMetadata.twitterUsername}
          />
          <div className='blog-container'>
            <Snakke
              color='#009eff'
              top='74px'
              height='3px'
              zIndex='999'
              shadow
            />
            <div
              className='blog-post-content'
              dangerouslySetInnerHTML={{
                __html: data.body?.childMarkdownRemark?.html,
              }}
            />
            {data.type === 'Case Studies' && (
              <div
                className='button'
                onClick={() => {
                  setOpenModal(true);
                }}
                style={{ marginTop: 80 }}
              >
                <span className='button-text'>Download</span>
              </div>
            )}
            <hr style={{ border: 'solid 1px #c1c1c1', marginTop: '100px' }} />
          </div>
        </div>
        <Modal
          closeTimeoutMS={900}
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          style={customStyles}
          contentLabel='Download Case Study'
        >
          {' '}
          <FontAwesomeIcon
            onClick={() => setOpenModal(false)}
            icon={faTimes}
            style={{
              color: '#666',
              float: 'right',
              cursor: 'pointer',
            }}
          />
          <div className='columns'>
            <div className='column'>
              <img
                src={logo}
                alt='logo'
                width='45%'
                style={{ marginTop: 27, marginLeft: 10 }}
              />
              <img
                src={image}
                alt='case study'
                width='100%'
                style={{
                  marginTop: '8%',
                  width: '80%%',
                  marginLeft: 10,
                }}
              />
              <p
                style={{
                  marginTop: 10,
                  fontFamily: 'Muli',
                  fontSize: 18,
                  fontWeight: 600,
                  marginLeft: 10,
                }}
              >
                Download our exclusive case study
              </p>
            </div>
            <div className='column'>
              <p className='subtext-width-career' style={{ marginTop: '40px' }}>
                Please enter your details
              </p>
              <form
                style={{ marginTop: 1 }}
                action='https://formsubmit.co/a1756621ef1b003d471d05687a018818'
                method='POST'
                onSubmit={onFireAlert}
                enctype='multipart/form-data'
              >
                <input type='hidden' name='_captcha' value='false'></input>
                <div className='columns'>
                  <div className='column'>
                    <input
                      className='input'
                      type='text'
                      placeholder='Name *'
                      style={{
                        background: '#F9F9F9',
                        boxShadow: 'none',
                        marginTop: 20,
                      }}
                      name='name'
                      required
                    />
                  </div>
                  <div className='column'>
                    <input
                      type='tel'
                      className='input'
                      name='phoneNumber'
                      placeholder='Phone Number *'
                      style={{
                        background: '#F9F9F9',
                        boxShadow: 'none',
                        marginTop: 20,
                      }}
                      id='phoneNumber'
                      title='Please use a numeric telephone number with no dashes or dots'
                      pattern='[0-9]*'
                      required
                    />
                  </div>

                  <input
                    type='hidden'
                    name='_cc'
                    value='sales@gonuclei.com,tanpreet.sehgal@gonuclei.com,siddharth.goyal@gonuclei.com,raghavendra.prasad@gonuclei.com'
                  />
                  <input
                    type='hidden'
                    name='_subject'
                    value='Someone just downloaded Case Study'
                  />
                  <input type='hidden' name='_captcha' value='false' />
                  {props.data.contentfulBlogPosting.caseStudyFile && (
                    <input
                      type='hidden'
                      name='_next'
                      value={`${props.data.site.siteMetadata.siteUrl}/resources/${data.slug}`}
                    />
                  )}
                </div>
                <div className='column'>
                  <input
                    className='input'
                    type='email'
                    placeholder='Email *'
                    style={{
                      background: '#F9F9F9',
                      boxShadow: 'none',
                      marginTop: 20,
                    }}
                    name='email'
                    required
                  />
                </div>
                <center>
                  <button
                    type='submit'
                    className='button'
                    style={{ marginTop: 50, marginBottom: 50 }}
                  >
                    <span className='button-text'>Download</span>
                  </button>
                </center>
              </form>
            </div>
          </div>
        </Modal>
        <>
          <ShareSheet
            isDesktop={false}
            isMobile={true}
            title={data.title}
            url={`${props.data.site.siteMetadata.siteUrl}/resources/${data.slug}`}
            userName={props.data.site.siteMetadata.twitterUsername}
          />
          <RelatedArticles choice={data.type} />
          <SubscribeBlog />
        </>
      </Layout>
    </React.Fragment>
  );
};

export default BlogPost;
