/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faLinkedin,
  faFacebook,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';
import divider from '../images/divider-colored.svg';

const ShareSheet = data => {
  const shareExtraContent = `, Nuclei’s SaaS Platform is today powering Merchant Marketplace for 16 major Banks across Asia, with over 3 million transactions per month, across 15 categories and 40 merchant partners`;
  return (
    <>
      {data.isDesktop && (
        <div className='icon-bar is-hidden-mobile' style={data.style}>
          <a>
            <TwitterShareButton
              style={{ outline: 'none' }}
              title={data.title + shareExtraContent}
              url={data.url}
              related={['gonuclei']}
              hashtags={['Gonuclei']}
              via={data.userName}
            >
              <FontAwesomeIcon
                color='#1DA1F2'
                icon={faTwitter}
                className='share-icons'
              />
            </TwitterShareButton>
          </a>
          <a>
            <LinkedinShareButton
              style={{ outline: 'none' }}
              title={data.title + shareExtraContent}
              summary=''
              source={data.url}
              url={data.url}
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                color='#2867B2'
                className='share-icons'
              />
            </LinkedinShareButton>
          </a>
          <a>
            <FacebookShareButton
              style={{ outline: 'none' }}
              title={data.title + shareExtraContent}
              url={data.url}
              quote={data.title + shareExtraContent}
            >
              <FontAwesomeIcon
                icon={faFacebook}
                color='#4267B2'
                className='share-icons'
              />
            </FacebookShareButton>
          </a>
          <a>
            <WhatsappShareButton
              style={{ outline: 'none' }}
              title={data.title + shareExtraContent}
              url={data.url}
            >
              <FontAwesomeIcon
                icon={faWhatsapp}
                color='#4AC959'
                className='share-icons'
              />
            </WhatsappShareButton>
          </a>
        </div>
      )}
      {data.isMobile && (
        <center>
          <div className='is-hidden-desktop is-hidden-tablet'>
            <img src={divider} alt='divider' />
            <p
              style={{
                color: '#333',
                fontFamily: 'Open Sans',
                fontWeight: 'bold',
                marginBottom: '35px',
                fontSize: 'clamp(18px,2vw,22px)',
              }}
            >
              Liked it? Why not share it with others
            </p>
            <a style={{ marginRight: '35px' }}>
              <TwitterShareButton
                style={{ outline: 'none' }}
                title={data.title + shareExtraContent}
                url={data.url}
                related={['gonuclei']}
                hashtags={['Gonuclei']}
                via={data.userName}
              >
                <FontAwesomeIcon
                  color='#1DA1F2'
                  className='share-icons'
                  icon={faTwitter}
                />
              </TwitterShareButton>
            </a>
            <a style={{ marginRight: '35px' }}>
              <LinkedinShareButton
                style={{ outline: 'none' }}
                title={data.title + shareExtraContent}
                summary=''
                source={data.url}
                url={data.url}
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className='share-icons'
                  color='#2867B2'
                />
              </LinkedinShareButton>
            </a>
            <a style={{ marginRight: '35px' }}>
              <FacebookShareButton
                style={{ outline: 'none' }}
                title={data.title + shareExtraContent}
                url={data.url}
                quote={data.title + shareExtraContent}
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  className='share-icons'
                  color='#4267B2'
                />
              </FacebookShareButton>
            </a>
            <a style={{ marginRight: '35px' }}>
              <WhatsappShareButton
                style={{ outline: 'none' }}
                title={data.title + shareExtraContent}
                url={data.url}
              >
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  className='share-icons'
                  color='#4AC959'
                />
              </WhatsappShareButton>
            </a>
          </div>
        </center>
      )}
    </>
  );
};

export default ShareSheet;
